import { DateRange } from 'moment-range';
import { ProjectRole } from '../config/global/ProjectRoles';
import {
  CustomReportType,
  ExplorerItemType,
  IssueStatus,
  MetaObjectType,
  ProcurementItemStatus,
  ProcurementQuoteStatus,
  PurchaseOrderStatus,
  SafetyKpiCategory,
  SafetyKpiQuantityType,
} from './enums';
import { FolderPermissionLevel } from 'src/components/FolderPermissionsModal';

export interface DateRangeFilter {
  dateProp: string;
  range: DateRange;
}

export interface FilterOption {
  prop: string;
  value: string | number | DateRange;
  dataType: string;
}

export interface GraphFilterOption extends FilterOption {
  dataSource: MetaObjectType;
  global: boolean;
}

export interface Activity {
  projectId: number;
  projectNumber: string;
  date: string;
  user: string;
  company: string;
  userId: string;
  action: string;
  value: string; // or "result"
  type: number;
  linkId: number;
  link: string;
}

export class ListItem {
  id: string | number;
  value: string;
  constructor(id: number | string, value: string) {
    this.id = id;
    this.value = value;
  }
}

export class OrderedListItem extends ListItem {
  order: number;
  constructor(id: number | string, value: string, order: number) {
    super(id, value);
    this.order = order;
  }
}

export class NewListItem extends ListItem {
  item: any;
  constructor(id: number | string, value: string, item: any) {
    super(id, value);
    this.item = item;
  }
}

export interface CcedUser {
  id: number;
  userId: string;
  userFullName: string;
  userEmail: string;
}

export type Alert = ListItem;

export interface ProjectLink {
  id: number;
  projectId: number;
  url: string;
  displayName: string;
}

export interface ContactCard {
  userId: string | number;
  name: string;
  phone: string;
  email: string;
}

export interface AttachmentInfo {
  id: number;
  path: string;
  fileName: string;
  entityId: number;
  entityName: string;
  //type: number
}

export interface IDocumentInfo {
  id: number;
  filePath: string;
  timestamp: string;
  userId: string;
  userFullName: string;
  userEmail: string;
}

export interface KeyDocViewModel {
  id: number;
  //docId: number,
  description: string;
  path: string;
  fileName: string;
}

export interface ReportSetting {
  userId: string;
  email: string;
  reportName: string;
  frequency: string;
}

export interface ProjectVm {
  id: number;
  name: string;
  projectNumber: string;
  siteId: string;
  wbs1: string;
  clientId: number;
  clientName: string;
  divisionId: number | null;
  divisionName: number | null;
  program: string;
  clientColor?: string;
  brandId: number;
  brandName: string;
  projectTypeId: number;
  projectType: string;
  projectStatusId: number;
  status: string;
  serviceLevelId: number;
  serviceLevel: string;
  budget: number;
  currentCost: number;
  currentStage: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  internalPmId: string;
  generalSuperId: string;
  externalPmId: string;
  ownerRepId: string;
  siteSuperId: string;
  internalPm: string;
  generalSuper: string;
  externalPm: string;
  ownerRep: string;
  startDate: string;
  endDate: string;
  budgetedDays: number;
  actualDays: number;
  completeDate?: string;
  showMilestones: boolean;
  latitude: number;
  longitude: number;
  userFavorite: boolean;
  alerts: Alert[];
  milestones: MilestoneEntryVm[];
  teamMembers: ProjectTeamMember[];
  links: ProjectLink[];
  teamMemberIds: string[];
  closeoutStarted: boolean;
  latestPause?: ProjectPauseLog;
  notes: IRecordNotes;
  copyTeamId: number | null;
  coordinatorId: string;
  coordinatorName: string;
  coordinatorEmail: string;
  safetyKPITemplateId?: number;
  permitExpirationDate: string;
}

export interface IRecordNotes {
  id: number;
  entityId: number;
  text: string;
  timestamp: string;
  userId: string;
  userFullName: string;
  userEmail: string;
  active: boolean;
}

export interface ProjectTeamMember extends CcedUser {
  projectId: number;
  roleId: number;
  company: string;
}

export interface ProjectDetailVm extends ProjectVm {
  materials: MaterialEntryVm[];
}

export interface ProjectPauseLog {
  id: number;
  projectId: number;
  userId: string;
  userFullName: string;
  userEmail: string;
  pauseNotes: string;
  startDate: string;
}

export interface UserProjectColumn {
  projectPropertyName: string;
  propertyDisplayName: string;
  columnOrder: number;
  valueFormatType: PropertyFormatType;
  userId: string;
}

export interface UserProjectNotifications {
  projectId: string;
  userId: string;
  userEmail: string;
  userFullName: string;
  fieldReportSubmittedNotification: boolean;
  fieldReportApprovedNotification: boolean;
  watchRFIs: boolean;
  watchSubmittals: boolean;
}

export enum PropertyFormatType {
  String,
  Date,
  Money,
}

export interface MilestoneEntryVm {
  entryId: number;
  milestoneId: number;
  projectId: number;
  project: ProjectVm;
  milestoneName: string;
  startDate: string;
  dueDate: string;
  duration: number;
  completeDate: string;
  status: string;
  assignedToId: string;
  notes: string;
  billable: boolean;
  dollarValue?: number;
  dependencies: MilestoneEntryDependencyVm[];
  //photos: AttachmentInfo[],
  //documents: AttachmentInfo[]
}

export interface MilestoneEntryDependencyVm {
  entryId: number;
  dependencyId: number;
  dependencyName: string;
}

export interface MaterialEntryVm {
  entryId: number;
  materialName: string;
  type: string;
  projectId: number;
  materialId: number;
  poDate: string;
  dueDate: string;
  poNumber: string;
  progress: number;
  leadTime: number;
  shipped: boolean | undefined;
  complete: string;
  vendorId: number | undefined;
  vendorName: string;
  cost: number;
  notes: string;
  milestoneId: number;
  milestoneName: string;
  outOfRange: boolean;
  closedOut: boolean;
}

export interface ReportLine {
  entryId: number;
  client: string;
  brandName: string;
  projectId: number;
  projectNumber: string;
  name: string;
  dueDate: string;
  internalPM: string;
  externalPM: string;
  notes: string;
  vendorName: string;
  entryLink: string;
  billable: boolean;
}

export interface NotificationPreference {
  id?: number;
  type: string;
  daysOut: number;
  enabled: boolean;
}

export interface UserInfo {
  userId: string;
  email: string;
  phoneNumber: string;
  fullName: string;
  roles: string[];
  subscriptions: string[];
  userActivated: boolean;
  defaultProjectTab: string;
  defaultDashboardTab: string;
  authenticationInfo: UserAuthenticationInfo;
  isSidebarPinned: boolean;
}

export interface UserAuthenticationInfo {
  twoFactorEnabled: boolean;
  emailConfirmed: boolean;
  smsVerified: boolean;
}

export interface SaveAction {
  type: 'SAVE';
  message?: string;
}

export interface ErrorAction {
  type: 'ERROR';
  message: string;
}

export interface ResetAction {
  type: 'RESET';
}

export interface DFR {
  id: number;
  date: Date;
  reportName: string;
  projectName: string;
  weather: Weather;
  comment: string;
  formalDescription: string;
  equipment: Equipment[];
  personnel: Personnel[];
  photos: DfrPhoto[];
  visitor: string;
  isBadWeather: boolean;
  userId: string;
  userFullName: string;
  low?: number;
  high?: number;
  summary: string;
  isLocked: boolean;
  isSubmitted: boolean;
  previousId?: number;
  nextId?: number;
  kpiEntries: FieldReportSafetyKpiEntry[];
  dailyFieldReportTemplateId?: number;
  dueDate?: Date;
}

export interface Weather {
  dfrId: number;
  low?: number;
  high?: number;
  humidity?: number;
  summary: string;
  precipPct: number;
  precipAmt: number;
  windSpeed: number;
  windDirection: string;
}

export interface Equipment {
  id: number;
  dfrId: number;
  name: string;
  company: string;
  quantity: number;
  isNonRemovable: boolean;
}

export interface Personnel {
  id: number;
  dfrId: number;
  count: number;
  name: string;
  title: string;
  company: string;
  hours: number;
  descriptionOfWork: string;
}

export interface DfrPhoto {
  id: number;
  dfrId: number;
  fileName: string;
  filePath: string;
  description: string;
  includeForClient: boolean;
  date: Date;
  result: DfrPhoto[] | never;
  isNonRemovable: boolean;
}

export interface DfrEvent {
  id: number;
  dfrId: number;
  company: string;
  name: string;
  eventType: string;
  detail: string;
}

export interface FieldReportExternalFile {
  id: number;
  dfrId: number;
  filePath: string;
  uploadTimestamp: string;
}

export interface FieldReportSafetyKpiEntry {
  id: number;
  dailyFieldReportId: number;
  category: SafetyKpiCategory;
  kpiText: string;
  value: string;
  quantity: number;
  quantityType: SafetyKpiQuantityType;
  comments: string;
  lastModifiedTimestamp: string;
  lastModifiedBy: string;
  lastModifiedByEmail: string;
  lastModifiedByName: string;
}

export interface DailyFieldReportTemplate {
  id: number;
  name: string;
  topics: string;
  equipment: string;
  photoDescriptions: string;
  topicList: string[];
  equipmentList: string[];
  photoDescriptionList: string[];
  userId: string;
  userEmail: string;
  userFullName: string;
  lastModifiedTimestamp: string;
  lastModifiedByEmail: string;
  lastModifiedByName: string;
}

export interface RFI {
  id: number;
  date: string;
  rfiNumber: string;
  projectId: number;
  subject: string;
  dueDate: string;
  suggestedAnswer: string;
  description: string;
  drawingRef: string;
  userFullName: string;
  userEmail: string;
  userId: string;
  sketchReference: string;
  currentReviewerId: string;
  currentReviewerName: string;
  scheduleImpact: number;
  costImpact: string;
  questionDocuments: RFIQuestionDocument[];
  reviewers: RFIReviewer[];
  ccedUsers: CcedUser[];
  responses: RFIResponse[];
  rfiComments: RFIResponseComment[];
  responseDocuments: RFIResponseDocument[];
  status: RFIStatus;
  approvalComment: string;
  approvedTimestamp: string;
  lastModTimestamp: string;
  late?: boolean;
  reissuedFromId: number;
  reissueId: number;
  reissueTimestamp: string;
  reissuedReason: string;
  approverId: string;
  approverName: string;
  approverEmail: string;
  creatorId: string;
  creatorName: string;
  creatorEmail: string;
}

export interface RFIDetailVm extends RFI {
  reissuedFromInfo: ListItem;
  reissuedToInfo: ListItem;
}

export interface RFIReviewer {
  rfiId: number;
  userId: string;
  userEmail: string;
  userFullName: string;
  approved: boolean;
  rejected: boolean;
  lastActionTimestamp: string;
  discipline: string;
}

export interface RFIResponse {
  id: number;
  rfiId: number;
  userId: string;
  userEmail: string;
  userFullName: string;
  response: string;
  isOfficial: boolean;
  status: RFIResponseStatus; // 1 = submitted, 2 = approved, 3 = rejected, 4 = reissued
  timestamp: string;
  lastModifiedTimestamp: string;
  comments: RFIResponseComment[];
  documents: RFIResponseDocument[];
}

export interface RFIResponseComment {
  id: number;
  responseId: number;
  rfiId: number;
  userId: string;
  userEmail: string;
  userFullName: string;
  comment: string;
  timestamp: string;
}

export interface RFIResponseDocument extends IDocumentInfo {
  id: number;
  rfiId: number;
  rfiReviewerResponseId: number;
  filePath: string;
  timestamp: string;
}

export interface RFIQuestionDocument extends IDocumentInfo {
  id: number;
  rfiId: number;
  fileName: string;
  filePath: string;
  description: string;
  includeForClient: boolean;
  date: Date;
}

export interface RFIReissueRequest {
  id: number;
  rfiId: number;
  rfiNumber: string;
  rfiSubject: string;
  projectId: number;
  reason: string;
  createdTimestamp: string;
  userId: string;
  userFullName: string;
  userEmail: string;
  status: RFIReissueRequestStatus;
  comments: string;
  reviewerId: string;
  reviewerName: string;
  reviewerEmail: string;
  copyOriginalDocs: boolean;
  files: ReissueRequestFile[];
}

export interface ReissueRequestFile {
  id: number;
  filePath: string;
}

export enum RFIStatus {
  AwaitingResponse,
  Answered,
  Accepted,
  Canceled,
  Reissued,
  ReissueRequested,
}

export enum RFIResponseStatus {
  Submitted,
  Approved,
  Rejected,
  Reissued,
}

export enum RFIReissueRequestStatus {
  Open,
  Approved,
  Rejected,
}

export interface SafetyKpiTemplate {
  id: number;
  name: string;
  entries: SafetyKpiTemplateEntry[];
  userId: string;
  userEmail: string;
  userFullName: string;
  lastModifiedTimestamp: string;
  lastModifiedByEmail: string;
  lastModifiedByName: string;
}

export interface SafetyKpiTemplateEntry {
  id: number;
  safetyKpiTemplateId: number;
  title: string;
  category: SafetyKpiCategory;
  quantityType: SafetyKpiQuantityType;
  userId: string;
  userEmail: string;
  userFullName: string;
}

export interface Submittal {
  id: number;
  projectId: number;
  submittalNumber: string;
  date: string;
  title: string;
  description: string;
  //divisionId: number,
  division: ConstructionDivision;
  //subDivisionId: number,
  subDivision: ConstructionDivision;
  dueDate: string;
  status: number;
  type: number;
  category: number;

  userId: string;
  userEmail: string;
  userFullName: string;

  reviewerId: string;
  reviewerEmail: string;
  reviewerName: string;

  vendorId: string;
  vendorEmail: string;
  vendorName: string;

  responses: SubmittalResponse[];
  responseType: number;
  approvalTimestamp: string;
  approvalComments: string;
  rejectionTimestamp: string;
  rejectionComments: string;
  ccedUsers: CcedUser[];

  documents: SubmittalDocument[];
  revisionNo: number;

  submittalPackageId?: number;
  copiedFrom?: number;
  copiedTo?: number;

  copyInfo: ListItem;
  sourceInfo: ListItem;

  late: boolean;
}

export interface SubmittalPackage {
  id: number;
  projectId: number;
  packageNumber: string;
  title: string;
  description: string;
  submittals: Submittal[];
  status: SubmittalPackageStatus;
  userId: string;
  userEmail: string;
  userFullName: string;
  completedTimestamp: string;
  late: boolean;
}

export interface ConstructionDivision {
  id: number;
  name: string;
}

export interface SubmittalDocument extends IDocumentInfo {
  submittalId: number;
  type: SubmittalDocumentType;
  responseId: number;
  status: SubmittalDocumentStatus;
  revisionNo: number;
}

export interface SubmittalResponse {
  id: number;
  submittalId: number;
  userId: string;
  userEmail: string;
  userFullName: string;
  comment: string;
  timeStamp: string;
  type: SubmittalResponseType;
  documents: SubmittalDocument[];
  revisionNo: number;
}

export interface SubmittalPostModel {
  projectId?: number;
  title?: string;
  description?: string;
  divisionId?: number;
  subDivisionId?: number;
  //subDivisionId?: number,
  packageId?: number;
  dueDate?: string;
  vendorId?: string;
  vendorEmail?: string;
  reviewerId?: string;
  type?: number;
  category?: number;
  ccIds: string[];
  sendToVendor?: boolean;
  submit?: boolean;
  voidReason?: string;
  ignoreOriginalDocuments: boolean;
}

export interface SubmittalPackagePostModel {
  projectId: number;
  title: string;
  description: string;
  submittalIds: number[];
  submit: boolean;
}

export interface SubmittalReviewModel {
  reviewType: SubmittalResponseType;
  comments: string;
  forwardReviewerUserId: string;
}

export enum SubmittalStatus {
  Draft,
  Submitted,
  Approved,
  Rejected,
  SentToSub,
  CreatorReview,
  Canceled,
  Void,
}

export enum SubmittalPackageStatus {
  Created,
  Sent,
  Complete,
}

export enum SubmittalCategory {
  MaterialOrFinish = 1,
  ProductData = 2,
  Samples = 3,
  ShopDrawing = 4,
  SpecSheet = 5,
  TestReport = 6,
  DesignDocument = 7,
}

export enum SubmittalResponseType {
  NoExceptions = 1,
  ApprovedWithExceptions = 2,
  ReviewAndResubmit = 3,
  Rejected = 4,
  SubmitSpecifiedItem = 5,
}

export enum SubmittalDocumentType {
  SubmittalDocument,
  ResponseDocument,
}

export enum SubmittalDocumentStatus {
  Unreviewed,
  Approved,
  Rejected,
}

export interface ImportConfig {
  id: number;
  clientId: number;
  companyName: string;
  source: ImportSource;
  serviceAccountEmail: string;
  privateKey: string;
  sheetId: string;
  sheetName: string;
  sheetIndex: number;
  useHeaderRow: boolean;
  headerRowIndex: number | null;
  rangeStart: string;
  rangeEnd: string;
  projectIdColumn: string;
  projectKey: string;
  eraseValueIfCellNull: boolean;
  active: boolean;
  frequencyType: RunFrequency;
  frequencyValue: number;
  lastRunDateTime: string;
  projectMap: ProjectImportMap;
  milestoneMaps: MilestoneImportMap[];
  expandProjectMap: boolean;
  expandMilestoneMap: boolean;
}

export interface ProjectImportMap {
  id: number;
  importConfigId: number;
  projectNumberColumn: string;
  siteIdColumn: string;
  projectNameColumn: string;
  projectTypeColumn: string;
  addressColumn: string;
  cityColumn: string;
  stateColumn: string;
  zipColumn: string;
  internalPmColumn: string;
  externalPmColumn: string;
  budgetColumn: string;
  startDateColumn: string;
  endDateColumn: string;
}

export interface MilestoneImportMap {
  id: number;
  importConfigId: number;
  dueColumn: string;
  completeColumn: string;
  notesColumn: string;
  milestoneId: number;
  milestoneName: string;
}

export enum ImportSource {
  Google,
  Excel,
  SmartSheets,
  Manual,
}

export enum RunFrequency {
  hourly,
  daily,
  weekly,
}

export interface ProjectTask {
  id: number;
  projectId: number;
  milestoneId: number;
  description: string;
  dueDate: string;
  completed: boolean;
  completeDate: string;
  userId: string;
  userEmail: string;
  userFullName: string;
  //assignedToId: string,
  //assignedToEmail: string,
  //assignedToName: string,
  projectName: string;
  projectNumber: string;
  milestoneName: string;
  assignees: ProjectTaskAssignee[];
}

export interface ProjectTaskAssignee {
  id: number;
  projectTaskId: number;
  userId: string;
  userEmail: string;
  userFullName: string;
}

export interface ClientVm {
  id: number;
  name: string;
  code: string;
  contactEmail: string;
  contactName: string;
  color: string;
  licenseCount: number;

  notes: IRecordNotes;
}

export interface SortInfo {
  sortProp: string;
  sortAsc: boolean;
}

export interface VendorVm {
  id: number;
  name: string;
  contactName: string;
  email: string;
  phone: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  laborType: string;
  vendorType: string;
  projectCount: number;
  averageRating: number;
  latitude: number;
  longitude: number;
  active: boolean;
  legalRestricted: boolean;
  ratings: VendorRating[];
  vendorProjects: VendorProject[];
  trades: VendorTrade[];
  markets: MarketSector[];
  divisions: CompanyDivision[];
  regions: WorkRegion[];
  users: UserInfo[];
  notes: IRecordNotes;
  legalNotes: IRecordNotes;
  federationCertifications: FederationCertification[];
  vettedPartnerId: number | undefined;
}

export interface VendorRating {
  id: number;
  vendorId: number;
  projectId: number;
  projectName: string;
  rating: number;
  notes: string;

  userId: string;
  userEmail: string;
  userFullName: string;
  timestamp: string;
}

export interface VendorProject {
  vendorId: number;
  projectId: number;
  projectName: string;
  vendorName: string;

  notes: string;

  rating?: VendorRating;
}

export interface MarketSector {
  id: number;
  name: string;
}
export interface VendorTrade {
  id: number;
  tradeName: string;
}

export interface UnvettedPartner {
  id: number;
  vendorName: string;
  state: string;
  city: string;
  zip: string;
  billingAddress: string;

  physicalState: string;
  physicalCity: string;
  physicalZip: string;
  physicalAddress: string;
  physicalCountry: string;

  distributedEnergyMarket: boolean;
  evMarket: boolean;
  retailMarket: boolean;
  evitpCertified: boolean;

  contactName: string;
  contactEmail: string;
  contactTitle: string;
  businessPhone: string;
  alternativePhone: string;
  referral: string;
  notes: string;
  union: string;
  country: string;
  vendorType: string;
  federationCertifications: FederationCertification[];
  trades: VendorTrade[];
  heldLicenses: HeldLicense[];
  serviceAreas: string[];

  yearsInBusiness: number;
  numberOfEmployees: number;
  modRate3Years: number[];
  hasSafetyPolicy: boolean;

  status: UnvettedPartnerStatus;
  conversionDate: string | null;

  f40Form: F40Form;
  financialsForm: FinancialsForm;
  sentLinks: LinkToken[];
  attachments: FormAttachment[];
}

export interface HeldLicense {
  id: number,
  partnerRegistrationDetailId: number | null,
  vendorId: number | null,
  licenseType: string,
  licenseNumber: string,
  state: string, 
}

export interface FederationCertification {
  id: number;
  partnerRegistrationDetailId: number;
  vendorId: number | null;
  fC: string;
  typeId: number;
  type: FederationCertificationType;
  attachment: FederationCertificationAttachment;
  dtActive: string;
  dtInactive: string;
  dtLastModified: string;
  vendor: VendorVm;
  partnerRegistrationDetail: UnvettedPartner;
}

export interface FederationCertificationType {
  id: number;
  name: string;
}

export interface FederationCertificationAttachment {
  id: number;
  federationCertificationId: number;
  filePath: string;
  federationCertification: FederationCertification;
  fileName: string;
}

export enum UnvettedPartnerStatus {
  PhaseOneSubmitted = 1,
  PhaseTwoSent = 2,
  PhaseTwoSubmitted = 3,
  AllFormsReviewed = 4,
}

export interface LinkToken {
  token: string;
  vendorId: number;
  expDate: string;
  submitLock: boolean;
  lastAccessed: string | null;
  financialsScope: boolean;
  f40Scope: boolean;
  insuranceScopes: string;
  isExpired: boolean;
}

export interface F40Form {
  id: number;
  vendorId: number;
  fullTimeEmployees: number;
  partTimeEmployees: number;
  seasonalEmployees: number;
  natureOfBusiness: number;
  isDivisionOfCorporation: boolean;
  parentCorpName: string;
  parentCorpAddress1: string;
  parentCorpAddress2: string;
  parentCorpCity: string;
  parentCorpStateProvince: string;
  parentCorpCountry: string;
  parentCorpZip: string;
  isSelfInsured: boolean;
  thirdPartyInsurer: string;
  usesSubcontractors: boolean;
  submitterName: string;
  submitterTitle: string;
  submitterPhone: string;
  submitterEmail: string;
  hasHealthSafetyProfessional: boolean;
  healthSafetyProName: string;
  healthSafetyProPhone: string;
  healthSafetyProLocation: string;
  hasSafetyProgram: boolean;
  doesSafetyProgramCoverAllActivities: boolean;
  coveredMgmtExpectations: boolean;
  coveredAccountabilities: boolean;
  coveredHazardControl: boolean;
  coveredWorkplaceAnalysis: boolean;
  coveredSafetyTraining: boolean;
  coveredIncidentReporting: boolean;
  f40FormSafetyTopicsId: number;
  safetyTopics: F40FormSafetyTopics;
  hasSHOrientationTraining: boolean;
  hasSHTraining: boolean;
  hasSHTrainingTests: boolean;
  hasSHTrainingDocumentation: boolean;
  hasCertsForAllMachineryOperators: boolean;
  hasJobSafetyAnalysis: boolean;
  proceduresWrittenAndReviewed: string;
  safetyPerformanceYears: F40FormSafetyPerformance[];
  hasReceivedOSHACitations: boolean;
  digitalSignature: string;
  digitalSignatureTitle: string;
  isDigitallySigned: boolean;
  lastModified: string;
  isSubmitted: boolean;
  submittedDate: string;
  reviewedDate: string;
  isReviewed: boolean;
  reviewerName: string;
  reviewNotes: string;

  companyName: string;
  address: string;
  city: string;
  stateProvince: string;
  zip: string;
  country: string;
  phone: string;

  attachments: FormAttachment[];

  [key: string]:
    | number
    | string
    | boolean
    | F40FormSafetyTopics
    | F40FormSafetyPerformance[]
    | FormAttachment[];
}

export interface F40FormSafetyTopics {
  id: number;
  vendorDetailId: number;
  f40FormId: number;
  confinedSpaces: string;
  controlHazardEnergy: string;
  firePrevention: string;
  ppe: string;
  weldingSafety: string;
  electricalSafety: string;
  handAndPoweredTools: string;
  respiratoryProtection: string;
  materialHandling: string;
  scaffolding: string;
  fallProtection: string;
  riggingAndCranes: string;
  excavationAndTrenches: string;
  grounding: string;
  emergencyActionPlans: string;
  hasFirstAidTrainedEmployees: boolean;
  hasDrugTestingPolicy: boolean;
  hasCDLEmployees: boolean;
  holdsSiteSafetyMeetings: boolean;
  frequencyOfSafetyMeetings: string;
  conductsSafetyAudits: boolean;
  safetyAuditsFrequency: string;
  hasDisciplinaryProcess: boolean;
  usesSafetyCriteriaForSubs: string;

  [key: string]: number | string | boolean;
}

export interface F40FormSafetyPerformance {
  id: number;
  vendorDetailId: number;
  f40FormId: number;
  year: number;
  emr: string;
  numberOfFatalities: string;
  numberOfRecordableCases: string;
  numberOfRestrictedCases: string;
  numberOfLostTimeCases: string;
  recordableIncidentRate: string;
  restrictedOnlyIncidentRate: string;
  lostTimeIncidentRate: string;
  laborHoursWorked: string;
  averageEmployeesOnPayroll: string;

  [key: string]: number | string | boolean;
}

export interface FinancialsForm {
  id: number;
  vendorDetailId: number;

  dbNumber: string;
  contractAmount1: string;
  contractAmount2: string;
  contractAmount3: string;
  subjectToLiens: boolean;
  liensDescription: string;
  recentFYMonth: string;
  recentFYYear: string;
  fyRevenues: string;
  fyCurrentAssets: string;
  fyTotalAssets: string;
  fyCurrentLiabilities: string;
  fyTotalLiabilities: string;
  fyLongTermDebt: string;
  fyRetainedEarnings: string;
  fyEquity: string;
  fyNetIncome: string;
  fyCashFlow: string;
  bankruptcyLast5Years: boolean;
  bankruptcyDescription: string;
  involvedInLitigation: boolean;
  litigationExplanation: string;
  singleJobBondingLimit: string;
  aggregateBondingLimit: string;
  claimAgainstBond: string;
  attachments: FormAttachment[];
  digitalSignature: string;
  digitalSignatureTitle: string;
  isDigitallySigned: boolean;
  lastModified: string;
  isSubmitted: boolean;
  submittedDate: string;
  reviewedDate: string;
  isReviewed: boolean;
  reviewerName: string;
  reviewNotes: string;

  companyName: string;
  address: string;
  city: string;
  stateProvince: string;
  zip: string;
  country: string;
  phone: string;
}

export interface FormAttachment {
  id: number;
  f40FormId?: number;
  filePath: string;
  type: string;
  fileName: string;
}

export interface CompanyDivision {
  id: number;
  name: string;
}

export interface WorkRegion {
  id: number;
  name: string;
  geoPoints: RegionGeoPoint[];
}

export interface RegionGeoPoint {
  id: number;
  workRegionId: number;
  latitude: number;
  longitude: number;
  order: number;
}

export interface CustomField {
  id: number;
  name: string;
  recordType: string;
  fieldType: CustomFieldType;
}

export interface CustomFieldEntry {
  id: number;
  recordId: number;
  fieldName: string;
  fieldType: CustomFieldType;
  value: object;
}

export enum CustomFieldType {
  String,
  Boolean,
  DateTime,
  Money,
}

export interface ChecklistVm {
  id: number;
  title: string;
  type: ChecklistType;
  clients: string;
  roles: string;
  isInheritable: boolean;
  canInherit: boolean;
  parentTemplateIds: number[];
  childTemplateIds: number[];
}

export interface ClientChecklistItemVm {
  id: number;
  title: string;
  frequency: string;
  duration: number;
}

export interface ChecklistDetailVm {
  id: number;
  title: number;
  isGlobalChecklist: boolean;
  type: ChecklistType;
  roleRestriction: boolean;
  roles: ProjectRole[];
  fields: ChecklistTemplateField[];
  sections: ChecklistTemplateSection[];
  renamable: boolean;
  functionType: ChecklistFunction;
  editors: ListItem[];
  userId: string;
  userFullName: string;
  userEmail: string;
  editorIds: string;
  parentTemplateIds: number[];
  childTemplateIds: number[];
  isInheritable: boolean;
  canInherit: boolean;
}

export interface ChecklistTemplateSection {
  id?: number;
  checklistTemplateId: number;
  title: string;
  subtitle: string;
  order: number;
  fields: ChecklistTemplateField[];
  isOriginal: boolean;
}

export interface ChecklistTemplateField {
  id: number;
  fieldName: string;
  subtitle: string;
  type: ChecklistFieldType;
  allowFiles: boolean;
  multiline: boolean;
  required: boolean;
  overallScore: boolean;
  order: number;
  minValue: number | null;
  maxValue: number | null;
  checklistTemplateSectionId: number | null;
  checklistTemplateId: number;
  isOriginal: boolean;
  options: ChecklistFieldOption[];
  conditions: ChecklistFieldCondition[];
  allowConditions: boolean;
}

export enum ChecklistType {
  Project = 1,
  Vendor = 2,
  Unspecified = 999,
}

export enum ChecklistFieldType {
  Text,
  Date,
  Checkbox,
  Number,
  Range,
  Option,
}

export interface ChecklistFunction {
  id: number;
  type: string;
}

export interface ChecklistItemVm {
  id: number;
  parentRecordId: number;
  headerTitle: string;
  parentRecordName: string;
  checklistTitle: string;
  createdDate: string;
  dueDate: string | null;
  lastModified: string | null;
  status: ChecklistStatus;
  type: ChecklistType;
  answeredFields: number;
  totalFields: number;
  locked: boolean;
}

export interface ChecklistDetail {
  id: number;
  templateId: number;
  title: string;
  parentRecordId: number;
  type: ChecklistType;
  createdDate: string;
  startedDate: string | null;
  dueDate: string | null;
  lastModifiedDate: string | null;
  lastModifiedById: string;
  lockedDueDate: string | null;
  approvedDate: string | null;
  status: ChecklistStatus;
  locked: boolean;
  lastModifiedByName: string;
  fieldEntries: ChecklistFieldEntry[];
  sections: ChecklistDetailSection[];
  functionType: ChecklistFunction;
  project: ProjectVm;
  clientName: string;
  statusName: string;
  renamable: boolean;
  comments: string;
  userId: string;
}

export interface ChecklistDetailSection {
  id: number;
  checklistHeaderId: number;
  title: string;
  subtitle: string;
  order: number;
  userId: string;
  fieldEntries: ChecklistFieldEntry[];
  status: ChecklistSectionStatus;
}

export enum ChecklistSectionStatus {
  NotStarted,
  Started,
  Completed,
}

export interface ChecklistFieldEntry {
  id: number;
  checklistHeaderId: number;
  fieldValue: string;
  files: ChecklistFieldEntryFile[];
  fieldName: string;
  subtitle: string;
  type: ChecklistFieldType;
  allowFiles: boolean;
  multiline: boolean;
  required: boolean;
  order: number;
  notes: string;
  minValue: number | null;
  maxValue: number | null;
  lastModified: Date | null;
  modifiedById: string;
  lastModifiedByName: string;
  checklistHeaderSectionId: number | null;
  options: ChecklistFieldOption[];
  conditions: ChecklistFieldCondition[];
}

export interface ChecklistFieldEntryFile extends IDocumentInfo {
  id: number;
  fieldEntryId: number;
  filePath: string;
  uploadedTimestamp: string;
  userId: string;
}

export enum ChecklistStatus {
  Started = 1,
  Completed = 2,
  Approved = 3,
  Canceled = 4,
}

export interface ChecklistFieldOption {
  id: number;
  text: string;
  order: number;
}

export interface ChecklistFieldCondition {
  id: number;
  value: string;
  conditionType: ChecklistConditionType;
}

export enum ChecklistConditionType {
  None,
  Photo,
  Comment,
  PhotoComment,
}

export interface ProcurementItem {
  id: number;
  projectId: number;
  itemDescription: string;
  procurementManagerId: string;
  createdDate: string;
  rfqDueDate: string;
  reviewDueDate: string;
  onSiteDueDate: string;
  budget: number;
  notes: string;
  leadTimeDays: number;
  status: ProcurementItemStatus;
  userId: string;
  userFullName: string;
  procurementManager: string;
  projectManagerId: string;
  projectNumber: string;
  lastModifiedBy: string;
  lastModified: string;
  lastModifiedTimestamp: string;
  reviewers: ProcurementItemReviewer[];
  procurementItemQuotes: ProcurementItemQuote[]; // represents the many-to-many mapping
  //quotes: ProcurementQuote;
  comments: ProcurementComment[];
  quoteSummaries: QuoteSummary[];
  attachments: ProcurementItemAttachment[];
  assigneeId: string;
  assigneeName: string;
  assigneeEmail: string;
  purchaseOrder: PurchaseOrder;
  poRequestTimestamp: string | null;
  rfq: RFQ;
  rfqId: number;
  shippingLocation: string;
}

export interface ProcurementItemReviewer {
  id: number;
  userId: string;
  userFullName: string;
  approvedQuoteId: number | null;
}

export interface PurchaseOrder {
  id: number;
  requestDate?: string;
  releaseDate?: string;
  userId: string;
  userFullName?: string;
  userEmail?: string;
  createdTimestamp?: string;
  status: PurchaseOrderStatus;
  description: string;
  poNumber: string;
  closedTimestamp?: string;
  closed: boolean;
}

export interface QuoteSummary {
  id: number;
  title: string;
  approved: boolean;
}

export interface ProcurementItemQuote {
  procurementItemId: number;
  procurementQuoteId: number;
}

export interface ProcurementQuote {
  id: number;
  projectId: number;
  rfqId: number | null;
  quoteNumber: string;
  projectManagerId: string;
  vendorId: string;
  vendorNameNoId: string;
  vendorName: string;
  createdDate: string;
  quoteDeliveryDate: string | null;
  leadTime: string;
  price: number;
  notes: string;
  status: ProcurementQuoteStatus;
  userId: string;
  userFullName: string;
  projectNumber: string;
  lastModifiedBy: string;
  lastModified: string;
  attachments: ProcurementQuoteAttachment[];
}

export interface ProcurementItemAttachment {
  id: number;
  procurementItemId: number;
  filePath: string;
  userId: string;
  timestamp: string;
  fileName: string;
}
//// ProcurementItemVm Interface
////
//export interface ProcurementItemVm {
//  id: number;
//  name: string;
//  status: ProcurementItemStatus;
//  RFQId?: number;
//}
////
////

export interface ProcurementQuoteAttachment {
  id: number;
  procurementQuoteId: number;
  filePath: string;
  userId: string;
  timestamp: string;
  fileName: string;
}

export interface ProcurementComment {
  id: number;
  userId: string;
  userFullName: string;
  timestamp: string;
  comment: string;
}

export interface PunchItem {
  id: number;
  projectId: number;
  title: string;
  createdDate: string;
  dueDate: string | null;
  lastModifiedDate: string;
  creatorId: string;
  userId: string;
  dateClosed: string;
  status: PunchItemStatus;
  description: string;
  assigneeId: string;
  category: string;
  photos: PunchItemPhoto[];
  ccedUsers: PunchItemCcedUser[];
  comments: PunchItemComment[];
}

export interface PunchItemPhoto {
  id: number;
  filePath: string;
  timestamp: string;
  description: string;
  punchItemId: number;
}

export interface PunchItemCcedUser extends CcedUser {
  id: number;
  recordId: number;
  punchItemId: number;
  userEmail: string;
  userFullName: string;
  userId: string;
}

export interface PunchItemComment {
  id: number;
  punchItemId: number;
  userId: string;
  timestamp: string;
  comment: string;
}

export enum PunchItemStatus {
  Draft,
  Sent,
  InReview,
  WorkRequired,
  Closed,
  Void,
}

export interface PunchItemVm extends PunchItem {
  canEdit: boolean;
  canReassignAndClose: boolean;
  assigneeName: string;
  ballInCourtName: string;
  creatorName: string;
  projectManagerId: string;
  projectManagerName: string;
  projectNumber: string;
  projectName: string;
  statusName: string;
  comments: PunchItemCommentVm[];
}

export interface PunchItemCommentPostModel {
  id: number;
  comment: string;
}

export interface PunchItemCommentVm extends PunchItemComment {
  userEmail: string;
  userFullName: string;
}

export interface PunchItemPostModel {
  projectId: number;
  title: string;
  category: string;
  assigneeId: string;
  userId?: string;
  description: string;
  dueDate: string | null;
  send: boolean;
  files: FileList | null;
}

export interface PunchlistTemplate {
  id: number;
  title: string;
  userId: string;
  lastModifiedTimestamp: string;
  items: PunchlistTemplateItem[];
}

export interface PunchlistTemplateVm extends PunchlistTemplate {
  userEmail: string;
  userFullName: string;
}

export interface RFQ extends RFQPostModel {
  id: number;

  projectName: string;
  itemId: number;
  rfqNumber: string;
  createdDate: string;
  quoteResponses: ProcurementQuote[];
  attachments: RFQAttachment[];
}

export interface RFQAttachment {
  id: number;
  rfqId: number;
  filePath: string;
  timestamp: string;
  fileName: string;
}

export interface RFQPostModel {
  recipients: RFQRecipient[];
  rfqDescription: string;
  dueDate: string;
  specAttached: boolean;
  spec: File | null;
  deliveryRequiredBy: string;
  specialInstructions: string;
  itemDescription: string;
  projectId: number;
  send: boolean;
  bccProcurementManager: boolean;
  itemId: number;
}

export interface RFQRecipient {
  id: number;
  rfqId: number;
  vendorId: number | null;
  companyName: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  sentTimestamp: string | null;
}

export interface RFQResponse {
  id: number;
  rfqId: number;
  modelPartNo: string;
  quantity: number;
  priceEach: number;
  extendedPrice: number;
  freightPrice: number;
  notes: string;
}

export interface PunchlistTemplateItem {
  id: number;
  punchlistTemplateId: number;
  title: string;
  category: string;
  description: string;
}

export interface TransferRecordPostModel {
  fromUserId: string;
  toUserId: string;
  recordTypes: TransferRecordType[];
  projectIds: number[];
}

export interface TransferSingleRecordPostModel {
  fromUserId: string;
  toUserId: string;
  recordType: TransferRecordType;
  recordId: number;
  reason: string;
}

export enum TransferRecordType {
  Milestones,
  ProcurementManager,
  ProjectCoordinator,
  ProjectGeneralSuper,
  ProjectManager,
  ProjectSiteSuper,
  ProjectTasks,
  RFIs,
  Submittals,
}

export interface ProcessingNotification {
  userId: string;
  timestamp: string;
  documentName: string;
  status: ProcessingStatus;
  downloadLink: string;
  runLink: string;
  isRead: boolean;
}

export enum ProcessingStatus {
  Processing,
  Completed,
  Downloaded,
}

export interface UpdateObject {
  fieldName: string;
  value: string;
}

export interface FileExplorerItem {
  name: string;
  path: string;
  type: ExplorerItemType;
  permissions: FolderPermissionLevel;
  isUserSubscribed: boolean;
}

export interface FileExplorerGetResponse {
  items: FileExplorerItem[];
  permissions: FolderPermissionLevel;
  isUserSubscribed: boolean;
}

export interface ReportDataQuery {
  id: number;
  queryName: string;
  rootTableName: string;
  rootTableAssemblyName: string;
  rootTableDisplayName: string;
  selectFields: QuerySelectField[];
  filters: QueryFilterCondition[];
  reports: CustomReport[];
  users: DataQueryUser[];
  includes: QueryInclude[];
  canEdit: boolean;
  dbSetName: string;
}

export interface QuerySelectField {
  id?: number;
  dataQueryId?: number;
  sourceTableName: string;
  propertyName: string;
  query: ReportDataQuery | null;
}

export interface QueryFilterCondition {
  id?: number;
  dataQueryId?: number;
  sourceTableName: string;
  sourceQualifiedAssemblyName: string;
  propertyName: string;
  operator: string;
  value: string;
  query: ReportDataQuery | null;
}

export interface CustomReport {
  id: number;
  reportName: string;
  dataQueryId: number;
  reportType: CustomReportType;
  creatorId: string;
  lastModifiedById: string;
  lastModifiedTimestamp: string;
  dataQuery: ReportDataQuery;
  columns: ReportColumnInfo[];
  reportUsers: CustomReportUser[];
}

export interface CustomReportVm
  extends Omit<CustomReport, 'columns' | 'reportUsers'> {
  columns: ReportColumn[];
  reportUsers: ReportUserVm[];
  reportTypeName: string;
  creatorName: string;
  lastModifiedByName: string;
  canEdit: boolean;
  isRunnable: boolean;
  validationMessage: string[];
  results: ReportResult;
}

export interface ReportResult {
  data: any[];
  runTimeUTC: string;
}

export interface ReportColumn
  extends Omit<ReportColumnInfo, 'variables' | 'customReport'> {
  variables: { [key: string]: number };
  isKeyColumn: boolean;
}

export interface ReportColumnInfo {
  id: number;
  reportId: number;
  displayName: string;
  propertyName: string;
  aggregationFunction: string;
  countDistinct: boolean;
  formula: string;
  format: string;
  prefix: string;
  postfix: string;
  order: number;
  variables: ReportColumnVariable[];
  customReport: CustomReport;
}

export interface ReportColumnVariable {
  id: number;
  columnInfoId: number;
  name: string;
  value: number;
  columnInfo: ReportColumnInfo;
}

export interface CustomReportUser {
  id: number;
  userEmail: string;
  recordId: number;
  userId: string;
  permissionsLevel: string;
  //customReport: CustomReport;
}

export interface ReportUserVm extends CustomReportUser {
  fullName: string;
}

export interface SchemaTableInfo {
  assemblyQualifiedName: string;
  tableName: string;
  displayName: string;
  navigationPropName: string;
  isCollection: boolean;
}

export interface SchemaPropertyInfo {
  sourceAssemblyQualifiedName: string;
  sourceTableName: string;
  propertyName: string;
  displayName: string;
}

export interface QueryInclude {
  id: number;
  dataQueryId: number;
  navigationPropName: string;
  isCollection: boolean;
  tableClassName: string;
  assmeblyQualifiedName: string;
  displayName: string;
  query?: ReportDataQuery;
  dbSetName: string;
}

export interface DataQueryUser {
  id: number;
  userEmail: string;
  recordId: number;
  userId: string;
  query: ReportDataQuery;
}

export interface UpdateAnnouncement {
  id: number;
  title: string;
  announcementDate: string;
  deploymentDate: string;
  isActive: boolean;
  pages: UpdateAnnouncementPage[];
}

export interface UpdateAnnouncementPage {
  id: number;
  announcementId: string;
  pageNo: number;
  contentPath: string;
}

export interface WhatsNewLogVm {
  timestamp: string;
  items: WhatsNewLogItem[];
}

export interface WhatsNewLogItem {
  note: string;
  subnotes: string[];
}

export interface Issue {
  id: number;
  title: string;
  typeId: number;
  type: IssueType;
  contributingFactorId: number | null;
  contributingFactor: IssueContributingFactor;
  description: string;
  userId: string;
  ccedUsers: IssueCcedUser[];
  responsibleParty: string;
  rootCauseId: number | null;
  rootCause: IssueRootCause;
  timelineImpact: number;
  status: IssueStatus;
  projectId: number;
  creatorId: string;
  createdDate: string;
  lastModifiedDate: string;
  dateClosed: string;
  attachments: IssueAttachment[];
}

export interface IssueCcedUser {
  id: number;
  userEmail: string;
  recordId: number;
  userId: string;
  issue: Issue;
}

export interface IssueAttachment extends IDocumentInfo {
  issueId: number;
  fileName: string;
}

export interface IssueType {
  id: number;
  name: string;
  issues: Issue[];
}

export interface IssueContributingFactor {
  id: number;
  name: string;
  description: string;
  issueTypeId: number;
  issueType: IssueType;
  issues: Issue[];
}

export interface IssueRootCause {
  id: number;
  name: string;
  description: string;
  issueTypeId: number;
  issueType: IssueType;
  issues: Issue[];
}

export interface IssueDisposition {
  id: number;
  name: string;
  description: string;
  issueTypeId: number;
  issueType: IssueType;
}

export interface IssueVm extends Issue {
  assigneeName: string;
  creatorName: string;
  canEdit: boolean;
  ccedUsers: IssueCcedUserVm[];
  projectManagerId: string;
  projectManagerName: string;
  projectNumber: string;
  projectName: string;
  statusName: string;
}

export interface IssueCcedUserVm extends IssueCcedUser {
  userFullName: string;
}

export interface IssuePostModel {
  title: string;
  typeId: number;
  contributingFactorId: number | null;
  description: string;
  ccedUsers: string[];
  responsibleParty: string;
  rootCauseId: number | null;
  timelineImpact: number;
  projectId: number;
  sendToAssignee: boolean;
  userId: string;
  fileList: FileList | null;
}

export interface IssueConfig {
  issueTypes: IssueType[];
  rootCauses: IssueRootCause[];
  contributorFactors: IssueContributingFactor[];
  dispositions: IssueDisposition[];
}

export interface NCR {
  id: number;
  supplier: string;
  nonConformanceReport: string;
  description: string;
  materialQuarantined: string;
  rootCause: string;
  rootCauseAnalysis: string;
  contributingFactors: string;
  correctiveActionPlan: string;
  witnessInspection: boolean;
  transportationDamage: boolean;
  disposition: string;
  closed: boolean;
  projectId: number;
  title: string;
  createdDate: Date;
  lastModifiedDate: Date;
  creatorId: string;
  userId: string;
  dateClosed: Date;
}

export interface NCRVm extends NCR {
  coreStatesRep: string;
  creatorName: string;
  canEdit: boolean;
  projectManagerId: string;
  projectManagerName: string;
  projectNumber: string;
  projectName: string;
}

export interface NCRPostModel {
  supplier: string;
  nonConformanceReport: string;
  description: string;
  materialQuarantined: string;
  rootCause: string;
  contributingFactors: string;
  witnessInspection: boolean;
  transportationDamage: boolean;
  disposition: string;
  closed: boolean;
  projectId: number;
  title: string;
  cAPFiles: FileList | null;
  rCAFiles: FileList | null;
}
